<template>
    <div class="CooperationNegotiate">
        <!-- <h1>合作洽谈</h1> -->
        <img src="https://img.youstarplanet.com/website/cooperationNegotiate/CooperationNegotiate.png" alt="">
        <div class="navigation" @click="CaseShow"><span>首页 > 合作洽谈</span></div>
        <div class="content">
            <div>项目合作</div>
            <p>
                邮箱：agent@youstarplanet.com<br>
            </p>
            <div>版权合作</div>
            <p>
                邮箱：cooperation@youstarplanet.com<br>
            </p>
            <div>平台合作</div>
            <p>
                邮箱：cooperation@youstarplanet.com<br>
            </p>
            <div>投融资合作</div>
            <p>
                邮箱：financing@youstarplanet.com<br>
            </p>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return { }
    },
    methods: {
        CaseShow(){
            this.$router.push({path:`/`})
        }
    },
    async mounted() {
        document.documentElement.scrollTop = 0
    },
}
</script>

<style lang="less">
.CooperationNegotiate{
    &>img{
        width: 100%;
        object-fit:cover;
    }
    .navigation{
        height: 48px;
        line-height: 48px;
        background-color: #F1F1F1;
        color: #8A8A8A;
        font-size: 14px;
        position: relative;
        top: -4px;
        span{
            display: block;
            width: 1270px;
            margin: 0 auto;
        }
    }
    .content{
        width: 1270px;
        margin: 68px auto 200px;
        padding: 0 80px;
        div{
            font-size: 28px;
            font-weight: bold;
            margin-bottom: 40px;
        }
        &>p{
            font-size: 16px;
            line-height: 35px;
            margin-left: 24px;
            margin-bottom: 76px;
        }
    }
}
</style>